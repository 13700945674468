import React, { useCallback, useEffect, useState } from 'react';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import { graphql, navigate } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import PageSection from 'ui-kit/page-section/page-section';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import InternalHeader from 'components/internal-header/internal-header.component';
import './index.style.scss';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';
import Button from 'ui-kit/button/button';
import EasyRefillPrescriptionCard from 'components/prescriptions-list/PrescriptionCard/EasyRefillPrescriptionCard';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import {
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillActions,
    easyRefillStartOrderRoutine,
    easyRefillRemoveRxToRefillRoutine,
    easyRefillAddRxToRefillRoutine
} from 'state/easy-refill/easy-refill.reducer';
import {
    easyRefillFirstNameSelector,
    easyRefillLoadingAddressSelector,
    easyRefillLoadingPaymentCardSelector,
    easyRefillLoadingRxsSelector,
    easyRefillPatientAddressSelector,
    easyRefillPatientPaymentCardSelector,
    easyRefillRxsSelector,
    easyRefillExpeditedShippingSelector,
    easyRefillUserBearerTokenSelector,
    easyRefillRxsLoadedSelector,
    easyRefillRxsToRefillSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillAllRxsSelector
} from 'state/easy-refill/easy-refill.selectors';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import { replaceStringWith } from 'util/string';
import Spinner from 'ui-kit/spinner/spinner';
import { CreateOrUpdateResponse, EasyRefillRxResult } from 'types/easy-refill';
import { easyRefillPrescriptionPayloadToProps } from 'components/prescriptions-list/PrescriptionCard/easyRefillPayloadToProps';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import { EasyRefillErrorModal } from '..';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';
import { Checkbox } from 'ui-kit/checkbox';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

const EasyRefillPrescriptions = ({ data }: GetEasyRefillPrescriptionLanguageAndImageDataQuery) => {
    const { t } = useTranslation();

    const { blueSkyBackground } = data;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleFormSubmit = useCallback(() => {
        setIsSubmitting(true);
        dispatch(
            easyRefillStartOrderRoutine.trigger({
                onSuccess: () => {
                    setIsSubmitting(false);
                    navigate('/easy-refill/review');
                },
                onFailure: (error: CreateOrUpdateResponse) => {
                    setIsSubmitting(false);
                    handleShowErrorModal(t('pages.easyRefill.prescriptions.errorCreatingOrder'));
                }
            })
        );
    }, [dispatch, handleShowErrorModal, t]);

    // selectors
    const easyRefillRxs = useSelector(easyRefillRxsSelector);
    const firstName = useSelector(easyRefillFirstNameSelector);
    const easyRefillLoadingRxs = useSelector(easyRefillLoadingRxsSelector);
    const easyRefillLoadingAddress = useSelector(easyRefillLoadingAddressSelector);
    const defaultShippingAddress = useSelector(easyRefillPatientAddressSelector);
    const loadingPaymentCard = useSelector(easyRefillLoadingPaymentCardSelector);
    const defaultPaymentCard = useSelector(easyRefillPatientPaymentCardSelector);
    const easyRefillExpeditedShipping = useSelector(easyRefillExpeditedShippingSelector);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const easyRefillRxsLoaded = useSelector(easyRefillRxsLoadedSelector);
    const easyRefillRxsToRefill = useSelector(easyRefillRxsToRefillSelector);
    const accountHasInsurance = useSelector(easyRefillAccountHasInsuranceSelector);
    const easyRefillAllRxSelected = useSelector(easyRefillAllRxsSelector);

    const { setShippingChoice } = easyRefillActions;

    const handleRefillPrescription = useCallback(
        (prescription: EasyRefillRxResult, isChecked: boolean) => {
            // if one is unchecked, we want to uncheck the refill all checkbox
            if (!isChecked) {
                dispatch(easyRefillRemoveRxToRefillRoutine.trigger({ rxNumber: prescription.rxNumber }));
            } else {
                dispatch(easyRefillAddRxToRefillRoutine.trigger({ rxNumber: prescription.rxNumber }));
            }
        },
        [dispatch]
    );

    const handleRefillAllPrescriptions = useCallback(
        (isChecked: boolean) => {
            easyRefillRxs.forEach(async (rx) => {
                handleRefillPrescription(rx, isChecked);
            });
        },
        [easyRefillRxs, handleRefillPrescription]
    );

    const handleSaveShippingPreference = useCallback(
        (expeditedShipping: boolean) => {
            dispatch(setShippingChoice(expeditedShipping));
        },
        [dispatch, setShippingChoice]
    );

    useEffect(() => {
        if (!easyRefillBearerToken) {
            navigate('/link-expired');
        }
    }, [easyRefillBearerToken]);

    useEffect(() => {
        // Get Patient Data
        // do not call the patient data if the rxResults already populated
        if (!easyRefillRxsLoaded) {
            dispatch(
                easyRefillGetPatientDataRoutine.trigger({
                    onFailure: (error: Pick<CreateOrUpdateResponse, 'messageText'>) => {
                        handleShowErrorModal(t('pages.easyRefill.prescriptions.errorRetrievingData'));
                    }
                })
            );
        }
        // get the patient data
        dispatch(easyRefillGetPatientAddressesRoutine.trigger());
        // get the patients payment info
        dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
    }, [dispatch, easyRefillRxsLoaded, handleShowErrorModal, t]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Easy Refill' }}
            suppressApplicationPage={true}
        >
            <PageSection>
                <Container fluid className={`easy-refill-prescriptions`}>
                    <InternalHeader
                        title={t('pages.easyRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.easyRefill.prescriptions.eyebrowText')}
                        sectionIndex={0}
                    />
                    <Row className="mb-5">
                        <Col className="d-flex flex-column justify-content-center align-items-center ">
                            <div className="spacer" />
                            <h3 className="text-center">
                                {easyRefillLoadingRxs ? (
                                    <Spinner isVisible={easyRefillLoadingRxs} t={t} />
                                ) : (
                                    t('pages.easyRefill.prescriptions.greeting', { firstName })
                                )}
                            </h3>
                        </Col>
                    </Row>
                    <Formik initialValues={{}} onSubmit={handleFormSubmit}>
                        {(props) => {
                            return (
                                <>
                                    {!easyRefillLoadingRxs && (
                                        <Row className="easy-refill-prescriptions-refill-all">
                                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                                <Checkbox
                                                    id="er-refill-all-prescriptions"
                                                    className="refill-all-prescriptions-checkbox"
                                                    name="refillAllPrescriptions"
                                                    defaultValue={easyRefillAllRxSelected}
                                                    label={t(`pages.easyRefill.prescriptions.refillAllPrescriptions`)}
                                                    onCheckChanged={(isChecked: boolean) =>
                                                        handleRefillAllPrescriptions(isChecked)
                                                    }
                                                    onClick={(isChecked: boolean) =>
                                                        handleRefillAllPrescriptions(isChecked)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                            <Row
                                                xs={1}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                                className={`d-flex ${
                                                    easyRefillLoadingRxs ? 'justify-content-center' : ''
                                                }`}
                                            >
                                                {!easyRefillLoadingRxs && (
                                                    <>
                                                        {easyRefillRxs.map((prescription, index) => (
                                                            <Col
                                                                className="mb-4 easy-refill-rx-card"
                                                                key={`rx-${index}`}
                                                            >
                                                                <EasyRefillPrescriptionCard
                                                                    key={`easy-refill-cabinet-rx-card-${prescription.rxNumber}`}
                                                                    {...easyRefillPrescriptionPayloadToProps(
                                                                        prescription,
                                                                        t,
                                                                        accountHasInsurance
                                                                    )}
                                                                    easyRefillOnChange={(prescription, isChecked) =>
                                                                        handleRefillPrescription(
                                                                            prescription,
                                                                            isChecked
                                                                        )
                                                                    }
                                                                    checked={easyRefillRxsToRefill.includes(
                                                                        prescription.rxNumber
                                                                    )}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                            <PrescriptionContentSectionHeader
                                                label={t(
                                                    'pages.easyRefill.prescriptions.sectionHeaders.shippingInformation'
                                                )}
                                                className="easy-refill-layout-content-section-header"
                                            />
                                            <Row>
                                                <Col className="easy-refill-prescriptions-section-content p-0">
                                                    <span className="easy-refill-prescriptions-section-sentence">
                                                        {t(
                                                            'pages.easyRefill.prescriptions.shippingInformation.sentenceOne'
                                                        )}
                                                        <a href="/sign-in">
                                                            {t(
                                                                'pages.easyRefill.prescriptions.paymentInformation.signIn'
                                                            )}
                                                        </a>
                                                        {t(
                                                            'pages.easyRefill.prescriptions.shippingInformation.sentenceTwo'
                                                        )}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="easy-refill-prescriptions-section-content p-0">
                                                    <div className="easy-refill-prescriptions-default-address-label">
                                                        <h6>
                                                            {t(
                                                                'pages.easyRefill.prescriptions.shippingInformation.defaultAddressLabel'
                                                            )}
                                                        </h6>
                                                    </div>
                                                    <div className="easy-refill-prescriptions-default-address">
                                                        {easyRefillLoadingAddress ? (
                                                            <LoadingMessage
                                                                isVisible={easyRefillLoadingAddress}
                                                                text={t(
                                                                    'pages.easyRefill.prescriptions.loading.address'
                                                                )}
                                                            />
                                                        ) : (
                                                            <>
                                                                {defaultShippingAddress !== undefined && (
                                                                    <>
                                                                        <span className="easy-refill-prescriptions-section-sentence">
                                                                            {defaultShippingAddress.address1}{' '}
                                                                            {defaultShippingAddress.address2
                                                                                ? defaultShippingAddress.address2
                                                                                : ''}
                                                                        </span>
                                                                        <span className="easy-refill-prescriptions-section-sentence">
                                                                            {defaultShippingAddress.city},{' '}
                                                                            {defaultShippingAddress.state}{' '}
                                                                            {defaultShippingAddress.zipcodeFour
                                                                                ? `${defaultShippingAddress.zipcode}-${defaultShippingAddress.zipcodeFour}`
                                                                                : defaultShippingAddress.zipcode}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                            <PrescriptionContentSectionHeader
                                                label={t(
                                                    'pages.easyRefill.prescriptions.sectionHeaders.shippingOptions'
                                                )}
                                                className="easy-refill-layout-content-section-header"
                                            />
                                            <Container className="p-0 d-flex flex-column" fluid>
                                                <Row className="easy-refill-prescriptions-shipping-options">
                                                    <Col className="p-0" xs={12}>
                                                        <label className="easy-refill-prescriptions-shipping-options-choice d-flex mt-4 mb-3">
                                                            <input
                                                                className="radio-toggle--input mr-2"
                                                                type="radio"
                                                                name="shippingOptionChoice"
                                                                checked={!easyRefillExpeditedShipping}
                                                                onChange={() => handleSaveShippingPreference(false)}
                                                            />
                                                            {t(
                                                                'pages.easyRefill.prescriptions.shippingOptions.freeShipping'
                                                            )}
                                                        </label>
                                                        <label className="easy-refill-prescriptions-shipping-options-choice d-flex">
                                                            <input
                                                                className="radio-toggle--input mr-2"
                                                                type="radio"
                                                                name="shippingOptionChoice"
                                                                checked={easyRefillExpeditedShipping}
                                                                onChange={() => handleSaveShippingPreference(true)}
                                                            />
                                                            {t(
                                                                'pages.easyRefill.prescriptions.shippingOptions.expeditedShipping'
                                                            )}
                                                        </label>
                                                        <div className="h6 easy-refill-prescriptions-section-sentence d-flex mt-4 mb-0 mx-0">
                                                            {t('pages.easyRefill.prescriptions.shippingOptions.text')}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                            <PrescriptionContentSectionHeader
                                                label={t(
                                                    'pages.easyRefill.prescriptions.sectionHeaders.paymentInformation'
                                                )}
                                                className="easy-refill-layout-content-section-header"
                                            />
                                            <Row>
                                                <Col className="easy-refill-prescriptions-section-content p-0">
                                                    <span className="easy-refill-prescriptions-section-sentence">
                                                        {t(
                                                            'pages.easyRefill.prescriptions.paymentInformation.sentenceOne'
                                                        )}
                                                        <a href="/sign-in">
                                                            {t(
                                                                'pages.easyRefill.prescriptions.paymentInformation.signIn'
                                                            )}
                                                        </a>
                                                        {t(
                                                            'pages.easyRefill.prescriptions.paymentInformation.sentenceTwo'
                                                        )}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="easy-refill-prescriptions-section-content p-0">
                                                    <div className="easy-refill-prescriptions-default-payment-method-label">
                                                        <h6>
                                                            {t(
                                                                'pages.easyRefill.prescriptions.paymentInformation.primaryPaymentMethod'
                                                            )}
                                                        </h6>
                                                    </div>
                                                    <div className="easy-refill-prescriptions-default-payment-method">
                                                        {loadingPaymentCard ? (
                                                            <LoadingMessage
                                                                isVisible={loadingPaymentCard}
                                                                text={t(
                                                                    'pages.easyRefill.prescriptions.loading.paymentCard'
                                                                )}
                                                            />
                                                        ) : (
                                                            <>
                                                                {defaultPaymentCard !== undefined ? (
                                                                    <>
                                                                        <p>
                                                                            {t(
                                                                                'pages.easyRefill.prescriptions.paymentInformation.cardEnding',
                                                                                {
                                                                                    cardType:
                                                                                        defaultPaymentCard.cardType,
                                                                                    endingDigits: replaceStringWith(
                                                                                        defaultPaymentCard.secureCardNumber,
                                                                                        new RegExp(/\*/g),
                                                                                        ''
                                                                                    )
                                                                                }
                                                                            )}
                                                                            <br />
                                                                            {defaultPaymentCard.cardName}
                                                                            <br />
                                                                            Exp. {defaultPaymentCard.cardExpiration}
                                                                        </p>
                                                                        <CreditCardIcon
                                                                            className="payment-card__credit-card-icon"
                                                                            variant={defaultPaymentCard.cardType}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <Disclaimer
                                                                        disclaimerText={`
                                                                            <span className="easy-refill-prescriptions-section-sentence">
                                                                                ${t(
                                                                                    'pages.easyRefill.prescriptions.noPaymentMethod.sentenceOne'
                                                                                )}
                                                                                <a href="/sign-in">
                                                                                    ${t(
                                                                                        'pages.easyRefill.prescriptions.noPaymentMethod.signIn'
                                                                                    )}
                                                                                </a>
                                                                                ${t(
                                                                                    'pages.easyRefill.prescriptions.noPaymentMethod.sentenceTwo'
                                                                                )}
                                                                            </span>`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={12}
                                            lg={{ span: 10, offset: 1 }}
                                            className="easy-refill-prescriptions-submit"
                                        >
                                            <Button
                                                async
                                                label={t('pages.easyRefill.prescriptions.continueBtn')}
                                                onClick={handleFormSubmit}
                                                type="submit"
                                                disabled={
                                                    !defaultPaymentCard ||
                                                    easyRefillRxsToRefill.length === 0 ||
                                                    isSubmitting
                                                }
                                                isBusy={isSubmitting}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            );
                        }}
                    </Formik>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillPrescriptions, 'easy-refill');

export const query = graphql`
    query GetEasyRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/transfer-rx-health.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
